/** @format */
import Navigation from "./Navigation";

function Layout(props) {
  return (
    <div className="w-screen h-screen flex flex-row bg-gray-200 overflow-y-hidden fixed">
      <div
        className="bg-gray-100 inline-block px-8 h-full "
        style={{ width: 300 }}
      >
        <Navigation />
      </div>
      <div className="inline-block bg-gray-300 flex-auto overflow-y-scroll">
        <div className="w-full">{props.children}</div>
      </div>
    </div>
  );
}

export default Layout;
