/** @format */
import React from "react";
import { Router } from "@reach/router";
import Layout from "./layout/Layout";
import Devices from "./pages/Devices";
import Playlists from "./pages/Playlists";

function App() {
  return (
    <Layout>
      <Router>
        <Playlists path="/playlists" />
        <Devices path="devices" />
      </Router>
    </Layout>
  );
}

export default App;
