/** @format */
import { useEffect, useState } from "react";
import useSWR from "swr";

const API_URL = process.env.REACT_APP_API_URL;

const fetcher = async (url) => {
  const res = await fetch(url);
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};

function Devices() {
  const { data, error, mutate } = useSWR(`${API_URL}playlists`, fetcher);
  const [showNewPlaylistEditor, setShowNewPlaylistEditor] = useState(false);
  return (
    <div className="p-4">
      <nav>
        <h1 className="text-xl bold mb-4">
          Playlists {data ? `[${data.playlists.length}]` : ""}
        </h1>
        <div className="inline-flex py-2 items-center w-full">
          <div className="inline-block flex-1 flex-grow-1">
            <CMSButon
              title="New"
              onClick={() => setShowNewPlaylistEditor(true)}
            />{" "}
          </div>
          <span>
            <input
              type="text"
              placeholder="Search"
              name="Search"
              className="px-4 py-1 mr-0 bg-gray-200 focus:underline"
            />
          </span>
        </div>
      </nav>
      {!data && <div>No Playlist</div>}
      {data && (
        <div>
          {data.playlists.map((p) => (
            <Playlist playlists={p} key={p._id} />
          ))}
        </div>
      )}
      {showNewPlaylistEditor && <PlaylistEditor />}
    </div>
  );
}
const defaultItem = {
  rows: [1, 1],
  cols: [1, 1],
  bg: "bg-gray-700",
  type: "image",
};

function PlaylistEditor() {
  const [playlistName, setPlaylistName] = useState("");
  const [content, setContent] = useState([]);
  const [columns, setColumns] = useState(1);
  const [rows, setRows] = useState(1);
  const [newItem, setNewItem] = useState(defaultItem);
  const [isSaved, setIsSave] = useState(false);

  const savePlaylist = () => {
    fetch(`${API_URL}playlist`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        playlist: { content, rows, columns, playlistName },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setIsSave(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="absolute top-0 left-0 w-screen h-screen bg-gray-900 opacity-50" />
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center ">
        <div className="w-4/5 h-4/5 bg-gray-200 inline-flex ">
          <div className="inline-block flex-1 overflow-scroll items-center">
            <div className="mt-0 px-4 py-4 bg-gray-300 text-right">
              <CMSButon
                title={isSaved ? "Saved" : "Save"}
                onClick={savePlaylist}
              />
            </div>
            <div className="text-center mt-8">
              <input
                placeholder="Playlist Name"
                className="w-4/5 px-4 py-2"
                type="text"
                value={playlistName}
                onChange={(e) => setPlaylistName(e.target.value)}
              />
            </div>

            <div className="text-center mt-8">
              <label className="mx-2">Columns</label>
              <input
                placeholder="Columns"
                className=" px-4 py-2 w-16"
                type="number"
                value={columns}
                onChange={(e) => setColumns(e.target.value)}
              />
              <label className="mx-2">Rows</label>
              <input
                placeholder="Rows"
                className=" px-4 py-2 w-16"
                type="number"
                value={rows}
                onChange={(e) => setRows(e.target.value)}
              />
            </div>
            <div className="mt-2 px-4 py-8 bg-gray-300">
              {!newItem && (
                <div>
                  <CMSButon
                    title="Add content"
                    selected
                    onClick={() => {
                      setNewItem(defaultItem);
                    }}
                  />
                </div>
              )}
              {newItem && (
                <div>
                  <h2 className="pb-4 text-base text-gray-500">New content</h2>
                  <div>
                    <label className="inline-block w-24">From column</label>
                    <input
                      placeholder="Columns"
                      className="mx-2 px-4 py-2 w-16"
                      type="number"
                      min={1}
                      max={columns}
                      value={newItem.cols[0]}
                      onChange={(e) =>
                        setNewItem({
                          ...newItem,
                          cols: [e.target.value, newItem.cols[1]],
                        })
                      }
                    />
                    <label>Span</label>
                    <input
                      placeholder="Span Columns"
                      className="mx-2 px-4 py-2 w-16"
                      type="number"
                      value={newItem.cols[1]}
                      min={1}
                      max={columns}
                      onChange={(e) =>
                        setNewItem({
                          ...newItem,
                          cols: [newItem.cols[0], e.target.value],
                        })
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className="inline-block w-24">From row</label>
                    <input
                      placeholder="Row"
                      className="mx-2 px-4 py-2 w-16"
                      type="number"
                      value={newItem.rows[0]}
                      min={1}
                      max={rows}
                      onChange={(e) =>
                        setNewItem({
                          ...newItem,
                          rows: [e.target.value, newItem.rows[1]],
                        })
                      }
                    />
                    <label>Span</label>
                    <input
                      placeholder="Span rows"
                      className="mx-2 px-4 py-2 w-16"
                      type="number"
                      value={newItem.rows[1]}
                      min={1}
                      max={rows}
                      onChange={(e) =>
                        setNewItem({
                          ...newItem,
                          rows: [newItem.rows[0], e.target.value],
                        })
                      }
                    />
                  </div>
                  <div className="py-2 flex items-center justify-space-between">
                    <label className="px-4">Type</label>
                    <select
                      className="inline-block px-2 py-2 w-32"
                      placeholder="Content Type"
                      onChange={(ev) =>
                        setNewItem({ ...newItem, type: ev.target.value })
                      }
                    >
                      <option name="text" value="image">
                        Image
                      </option>
                      <option name="text" value="video">
                        Video
                      </option>
                      <option name="text" value="html">
                        HTML
                      </option>
                      <option name="text" value="url">
                        URL
                      </option>
                      <option name="text" value="text">
                        Text
                      </option>
                      <option name="text" value="widget">
                        Widget
                      </option>
                    </select>
                    <div className="inline-block flex-1" />
                    <CMSButon
                      title="Add"
                      selected
                      onClick={() => {
                        setContent([...content, { ...newItem, bg: "#000" }]);
                        setNewItem(null);
                      }}
                    />
                  </div>
                  <div className="px-4 py-2">
                    <input
                      placeholder="url"
                      className="px-4 py-2 w-full"
                      value={newItem.url ?? ""}
                      onChange={(ev) =>
                        setNewItem({ ...newItem, url: ev.target.value })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="inline-flex flex-1 bg-gray-800 items-center justify-center">
            <div
              className="inline-block flex-0 border-2 border-gray-700"
              style={{ width: 640, height: 360 }}
            >
              <div
                style={{
                  width: 1920,
                  height: 1080,

                  transform: "scale3d(0.33,0.33,0.33)",
                  transformOrigin: "0% 0%",
                  display: "inline-block",
                }}
              >
                <div className="inline-block w-full h-full">
                  <div
                    className={`w-full h-full grid grid-cols-${columns} grid-rows-${rows} grid-flow-col`}
                  >
                    {content.map((item, i) => (
                      <div
                        key={`${i}${item.cols[0]} ${item.cols[1]} ${item.rows[0]} ${item.rows[1]}`}
                        className={`col-start-${item.cols[0]} col-span-${item.cols[1]} row-start-${item.rows[0]} row-span-${item.rows[1]} ${item.bg} inline-flex items-center justify-center border-gray-700 border-2 text-xl`}
                      >
                        <div className="inline-block w-full h-full ">
                          {item.type === "image" && (
                            <img
                              src={item.url}
                              alt=""
                              className="inline-block w-full h-full object-contain"
                            />
                          )}
                          {item.type === "url" && (
                            <div
                              className="inline-block w-full h-full "
                              style={{}}
                            >
                              <iframe
                                height="100%"
                                width="100%"
                                src={item.url}
                                title="23"
                              />
                            </div>
                          )}
                          {item.type === "video" && (
                            <video
                              src={item.url}
                              autoPlay={true}
                              loop={true}
                              muted={true}
                              type="video/mp4"
                              width="100%"
                              className="inline-block w-full h-full object-contain"
                            />
                          )}
                          {item.type === "html" && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.body,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                    {newItem && (
                      <div
                        className={`col-start-${newItem.cols[0]} col-span-${newItem.cols[1]} row-start-${newItem.rows[0]} row-span-${newItem.rows[1]} ${newItem.bg} inline-flex items-center justify-center border-feg-yellow border-2 text-xl`}
                      >
                        <div className="inline-block w-full h-full relative">
                          <div className="inline-block w-full h-full relative ">
                            {newItem.type === "image" && (
                              <img
                                src={newItem.url}
                                alt=""
                                className="inline-block w-full h-full object-contain"
                              />
                            )}
                            {newItem.type === "url" && (
                              <div
                                className="inline-block w-full h-full "
                                style={{}}
                              >
                                <iframe
                                  height="100%"
                                  width="100%"
                                  src={newItem.url}
                                  title="23"
                                />
                              </div>
                            )}
                            {newItem.type === "video" && (
                              <video
                                src={newItem.url}
                                autoPlay={true}
                                loop={true}
                                muted={true}
                                type="video/mp4"
                                width="100%"
                                className="inline-block w-full h-full object-contain"
                              />
                            )}
                            {newItem.type === "html" && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: newItem.body,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Playlist(props) {
  const { playlist } = props;
  return (
    <div className="py-2 px-2 bg-gray-200 hover:bg-gray-100 mb-1 flex">
      <div className="inline-block flex-1 inline-flex">
        <div
          className="font-bold text-lg px-2 flex justify-start items-center"
          style={{ width: 100 }}
        >
          {playlist._id}
        </div>
      </div>
    </div>
  );
}

function CMSButon(props) {
  const { title, selected, onClick } = props;
  return (
    <span
      onClick={onClick}
      className={`px-4 cursor-pointer py-2 mr-2 hover:bg-white hover:font-bold bg-gray-200  ${
        selected ? "bg-gray-100 font-bold" : ""
      }`}
    >
      {title}
    </span>
  );
}

export default Devices;
