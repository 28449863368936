/** @format */

import { Link } from "@reach/router";

function Navigation() {
  return (
    <div>
      <h1 className="mt-16">CMS DEMO</h1>
      <nav className="flex flex-col py-24">
        <Link to="/devices">Devices</Link>
        <Link to="/playlists">Playlists</Link>
      </nav>
    </div>
  );
}

export default Navigation;
