/** @format */
import { useEffect, useState } from "react";
import useSWR from "swr";

const API_URL = process.env.REACT_APP_API_URL;

const fetcher = async (url) => {
  const res = await fetch(url);
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};

function Devices() {
  const [registrationFilter, setRegistrationFilter] = useState(false);
  const { data, error, mutate } = useSWR(
    `${API_URL}devices?registered=${registrationFilter}`,
    fetcher
  );

  return (
    <div className="p-4">
      <nav>
        <h1 className="text-xl bold mb-4">
          Devices {data ? `[${data.devices.length}]` : ""}
        </h1>
        <div className="inline-flex py-2 items-center w-full">
          <FilterButon
            title="Active"
            selected={registrationFilter === true}
            onClick={() => setRegistrationFilter(true)}
          />
          <FilterButon
            title="New"
            selected={registrationFilter === false}
            onClick={() => setRegistrationFilter(false)}
          />
          <div className="inline-block flex-1 flex-grow-1"> </div>
          <span>
            <input
              type="text"
              placeholder="Search"
              name="Search"
              className="px-4 py-1 mr-0 bg-gray-200 focus:underline"
            />
          </span>
        </div>
      </nav>
      {!data && <div>Loading</div>}
      {data && (
        <div>
          {registrationFilter &&
            data.devices.map((d) => (
              <RegisteredDevice device={d} key={d._id} />
            ))}
          {!registrationFilter &&
            data.devices.map((d) => <NewDevice device={d} key={d._id} />)}
        </div>
      )}
    </div>
  );
}

function RegisteredDevice(props) {
  const { device } = props;
  return (
    <div className="py-2 px-2 bg-gray-200 hover:bg-gray-100 mb-1 flex">
      <div className="inline-block flex-1 inline-flex">
        <div
          className="font-bold text-lg px-2 flex justify-start items-center"
          style={{ width: 100 }}
        >
          {device._id}
        </div>

        <div className="inline-block flex-1">
          <div className="inline-block inline-flex flex-row">
            <div className="text-sm font-bold mr-2">
              {device.width}x{device.height}
            </div>
            <div className="text-sm">{device.ip}</div>
          </div>
          <div className="text-xs text-gray-700">{device.ua}</div>
        </div>
      </div>
      <div className="text-right px-2 items-center inline-flex justify-center"></div>
    </div>
  );
}

function NewDevice(props) {
  const { device } = props;
  const [isActivated, setIsActivated] = useState(device.registered || false);

  const activateDevice = (id) => {
    fetch(`${API_URL}devices/activate?code=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok === true) {
          setIsActivated(true);
        }
      });
  };
  return (
    <div className="py-2 px-2 bg-gray-200 hover:bg-gray-100 mb-1 flex">
      <div className="inline-block flex-1 inline-flex">
        <div
          className="font-bold text-lg px-2 flex justify-start items-center"
          style={{ width: 100 }}
        >
          {device._id}{" "}
        </div>

        <div className="inline-block flex-1">
          <div className="inline-block inline-flex flex-row">
            <div className="text-sm font-bold mr-8">
              {device.width}x{device.height}
            </div>

            <div className="text-xs">{device.ip}</div>
          </div>
          <div className="text-xs text-gray-700">{device.ua}</div>
        </div>
      </div>
      <div className="text-right px-2 items-center inline-flex justify-center">
        <span
          className={`${
            isActivated
              ? "cursor-not-allowed text-gray-600"
              : "hover:underline cursor-pointer"
          }`}
          onClick={() => {
            if (!isActivated) {
              activateDevice(device._id);
            }
          }}
        >
          Activate{isActivated ? "d" : ""}
        </span>
      </div>
    </div>
  );
}

function FilterButon(props) {
  const { title, selected, onClick } = props;
  return (
    <span
      onClick={onClick}
      className={`px-4 cursor-pointer py-2 mr-2 hover:bg-white hover:font-bold  ${
        selected ? "bg-gray-100 font-bold" : ""
      }`}
    >
      {title}
    </span>
  );
}

export default Devices;
